const clippings = {
    'd2b4c17e-3b69-5e4b-bb85-cced781e356a': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The tendency to change their story. They become investors “for the long haul” when they are losing money, switching back and forth between traders and investors to fit recent reversals of fortune. The difference between a trader and an investor lies in the duration of the bet, and the corresponding size. There is absolutely nothing wrong with investing “for the long haul,” provided one does not mix it with short-term trading—it is just that many people become long-term investors after they lose money, postponing their decision to sell as part of their denial.',
        location: '2019-23',
        time: 'Tuesday, May 04, 2021, 03:39 PM',
        favourite: false,
        deleted: false,
    },
    '3d16a574-d06b-5fff-b092-0c5873677f95': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'No precise game plan ahead of time as to what to do in the event of losses. They simply were not aware of such a possibility. Both bought more bonds after the market declined sharply, but not in response to a predetermined plan. ',
        location: '2023-26',
        time: 'Tuesday, May 04, 2021, 03:39 PM',
        favourite: false,
        deleted: false,
    },
    '134f5a80-091d-5765-8431-1048e72bc3ca': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'No precise game plan ahead of time as to what to do in the event of losses. They simply were not aware of such a possibility. Both bought more bonds after the market declined sharply, but not in response to a predetermined plan. Absence of critical thinking expressed in absence of revision of their stance with “stop losses.” Middlebrow traders do not like selling when it is “even better value.” They did not consider that perhaps their method of determining value is wrong, rather than the market failing to accommodate their measure of value. They may be right, but, perhaps, some allowance for the possibility of their methods being flawed was not made. For all his flaws, we will see that Soros seems rarely to examine an unfavorable outcome without testing his own framework of analysis. Denial. When the losses occurred there was no clear acceptance of what had happened. The price on the screen lost its reality in favor of some abstract “value.” In classic denial mode, the usual “this is only the result of liquidation, distress sales” was proffered. They continuously ignored the message from reality.',
        location: '2023-33',
        time: 'Tuesday, May 04, 2021, 03:40 PM',
        favourite: false,
        deleted: false,
    },
    '776bc46a-8b7a-5077-855a-df21b354d077': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'How could traders who made every single mistake in the book become so successful? Because of a simple principle concerning randomness. This is one manifestation of the survivorship bias. We tend to think that traders were successful because they are good. Perhaps we have turned the causality on its head; we consider them good just because they make money. One can make money in the financial markets totally out of randomness.',
        location: '2034-37',
        time: 'Tuesday, May 04, 2021, 03:40 PM',
        favourite: false,
        deleted: false,
    },
    '99f93bfd-0c3d-56ae-8c3f-a21f889b67da': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The further research by Gould uncovered a very different story from the information he had initially been given; mainly that the expected (i.e., average) survival was considerably higher than eight months. It came to his notice that expected and median do not mean the same thing at all. Median means roughly that 50% of the people die before eight months and 50% survive longer than eight months.',
        location: '2097-2100',
        time: 'Tuesday, May 04, 2021, 03:47 PM',
        favourite: false,
        deleted: false,
    },
    'd6dc18ef-f6de-59a9-ad89-a187e281209f': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'If rational traders detect a pattern of stocks rising on Mondays, then, immediately such a pattern becomes detectable, it would be ironed out by people buying on Friday in anticipation of such an effect. There is no point searching for patterns that are available to everyone with a brokerage account; once detected, they would be self-canceling.',
        location: '2389-91',
        time: 'Wednesday, May 05, 2021, 12:18 AM',
        favourite: false,
        deleted: false,
    },
    '25d0c06d-39ea-5f14-acb3-14a0dee5beb9': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'No amount of observations of white swans can allow the inference that all swans are white, but the observation of a single black swan is sufficient to refute that conclusion.',
        location: '2415-16',
        time: 'Wednesday, May 05, 2021, 12:21 AM',
        favourite: false,
        deleted: false,
    },
    '475851ed-e4f3-5f42-a47b-73705b357ec2': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The problem is that, without a proper method, empirical observations can lead you astray. Hume came to warn us against such knowledge, and to stress the need for some rigor in the gathering and interpretation of knowledge—what is called epistemology (from episteme, Greek for learning). Hume is the first modern epistemologist (epistemologists operating in the applied sciences are often called methodologists or philosophers of science).',
        location: '2420-23',
        time: 'Wednesday, May 05, 2021, 12:22 AM',
        favourite: false,
        deleted: false,
    },
    'e6c42325-809a-5235-a7a7-c9510cc114ff': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Another logical flaw in this type of historical statement is that often when a large event takes place, you hear the “it never happened before,” as if it needed to be absent from the event’s past history for it to be a surprise. So why do we consider the worst case that took place in our own past as the worst possible case? If the past, by bringing surprises, did not resemble the past previous to it (what I call the past’s past), then why should our future resemble our current past?',
        location: '2466-70',
        time: 'Wednesday, May 05, 2021, 12:28 AM',
        favourite: false,
        deleted: false,
    },
    '262912cd-2119-538c-ab1d-5b7586c7e07c': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'I also learned to stay away from people of a competitive nature, as they have a tendency to commoditize and reduce the world to categories, like how many papers they publish in a given year, or how they rank in the league tables. There is something nonphilosophical about investing one’s pride and ego into a “my house/library/car is bigger than that of others in my category”—it is downright foolish to claim to be first in one’s category all the while sitting on a time bomb.',
        location: '2481-85',
        time: 'Wednesday, May 05, 2021, 12:30 AM',
        favourite: false,
        deleted: false,
    },
    '18d1d40b-5a55-5842-aa3d-77b570bd159d': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'I will refrain from commonplace discourse about the divorce between those who have ideas and those who carry them in practice, except to bring out the interesting behavioral problem; we like to emit logical and rational ideas but we do not necessarily enjoy this execution.',
        location: '2605-7',
        time: 'Wednesday, May 05, 2021, 12:47 AM',
        favourite: false,
        deleted: false,
    },
    '302d6b99-c6b2-5113-9655-0c59f6af5372': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'history both to make their bets and to measure their risk). They would then terminate their trade. This is called a stop loss, a predetermined exit point, a protection from the black swan. I find it rarely practiced.',
        location: '2626-27',
        time: 'Wednesday, May 05, 2021, 12:50 AM',
        favourite: false,
        deleted: false,
    },
    '8c64f3e4-327a-54f2-8edb-0a89739c2ccd': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'a budding knowledge of probability laws is to base their decision making on the following principle: It is very unlikely for someone to perform considerably well in a consistent fashion without his doing something right.',
        location: '2649-50',
        time: 'Thursday, May 06, 2021, 01:13 AM',
        favourite: false,
        deleted: false,
    },
    '46e27d15-bdc5-5a3d-857c-2c56812dd0db': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Track records therefore become preeminent. They call on the rule of the likelihood of such a successful run and tell themselves that if someone performed better than the rest in the past then there is a great chance of his performing better than the crowd in the future—and a very great one at that. But, as usual, beware the middlebrow: A small knowledge of probability can lead to worse results than no knowledge at all.',
        location: '2650-53',
        time: 'Thursday, May 06, 2021, 01:13 AM',
        favourite: false,
        deleted: false,
    },
    '0cc82fb1-b5fa-57a0-bff1-26019f465f7a': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Aside from the misperception of one’s performance, there is a social treadmill effect: You get rich, move to rich neighborhoods, then become poor again. To that add the psychological treadmill effect; you get used to wealth and revert to a set point of satisfaction. This problem of some people never really getting to feel satisfied by wealth (beyond a given point) has been the subject of technical discussions on happiness.',
        location: '2734-37',
        time: 'Thursday, May 06, 2021, 10:31 PM',
        favourite: false,
        deleted: false,
    },
    'c00d4d8d-8071-5771-b18c-b12b975d4d49': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'I will set aside the point that I see no special heroism in accumulating money, particularly if, in addition, the person is foolish enough to not even try to derive any tangible benefit from the wealth (aside from the pleasure of regularly counting the beans). I have no large desire to sacrifice much of my personal habits, intellectual pleasures, and personal standards in order to become a billionaire like Warren Buffett, and I certainly do not see the point of becoming one if I were to adopt Spartan (even miserly) habits and live in my starter house.',
        location: '2756-60',
        time: 'Thursday, May 06, 2021, 10:33 PM',
        favourite: false,
        deleted: false,
    },
    '31cf45be-b26d-5623-ad7d-6815d48e571a': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Becoming rich is not directly a moral achievement, but that is not where the severe flaw in the book lies.',
        location: '2764-65',
        time: 'Thursday, May 06, 2021, 10:34 PM',
        favourite: false,
        deleted: false,
    },
    'e9569ce4-a73c-5cff-afb7-7bbffd1bbb8b': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The authors made no attempt to correct their statistics with the fact that they saw only the winners. They make no mention of the “accumulators” who have accumulated the wrong things (members of my family are experts on that; those who accumulated managed to accumulate currencies about to be devalued and stocks of companies that later went bust).',
        location: '2770-72',
        time: 'Thursday, May 06, 2021, 10:35 PM',
        favourite: false,
        deleted: false,
    },
    '90a4711e-89c9-5ac6-83ce-9270c36f207e': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The story focuses on an unusual episode in history; buying its thesis implies accepting that the current returns in asset values are permanent (the sort of belief that prevailed before the great crash that started in 1929).',
        location: '2778-79',
        time: 'Thursday, May 06, 2021, 10:36 PM',
        favourite: false,
        deleted: false,
    },
    '6880ddb9-9802-5950-9431-4f52a76230eb': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The mistake of ignoring the survivorship bias is chronic, even (or perhaps especially) among professionals. How? Because we are trained to take advantage of the information that is lying in front of our eyes, ignoring the information that we do not see.',
        location: '2790-91',
        time: 'Thursday, May 06, 2021, 10:37 PM',
        favourite: false,
        deleted: false,
    },
    '41be8fc7-3f7d-5a8d-96fa-ef8d35072d0d': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Optimism, it is said, is predictive of success. Predictive? It can also be predictive of failure. Optimistic people certainly take more risks as they are overconfident about the odds; those who win show up among the rich and famous, others fail and disappear from the analyses. Sadly.',
        location: '2815-17',
        time: 'Thursday, May 06, 2021, 10:40 PM',
        favourite: false,
        deleted: false,
    },
    'e469c888-32bf-5a66-94d0-52fa7eabfaee': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'We have now, simply in a fair game, 313 managers who made money for five years in a row. Out of pure luck. Meanwhile if we throw one of these successful traders into the real world we would get very interesting and helpful comments on his remarkable style, his incisive mind, and the influences that helped him achieve such success. Some analysts may attribute his achievement to precise elements among his childhood experiences. His biographer will dwell on the wonderful role models provided by his parents; we would be supplied with black-and-white pictures in the middle of the book of a great mind in the making.',
        location: '2877-81',
        time: 'Thursday, May 06, 2021, 10:47 PM',
        favourite: false,
        deleted: false,
    },
    '7d25bd4a-3124-5390-a578-493a636ad11d': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'A result is that in real life, the larger the deviation from the norm, the larger the probability of it coming from luck rather than skills: Consider that even if one has 55% probability of heads, the odds of ten wins is still very small.',
        location: '2914-15',
        time: 'Thursday, May 06, 2021, 10:51 PM',
        favourite: false,
        deleted: false,
    },
    'a86c57ea-06d2-5fa4-b482-593fceb8a8c9': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'You go spill your tears on your neighbor’s shoulder and he tells you that he remembers that he received two such mysterious letters. But the mailings stopped at the second letter. He recalls that the first one was correct in its prediction, the other incorrect. What happened? The trick is as follows. The con operator pulls 10,000 names out of a phone book. He mails a bullish letter to one half of the sample, and a bearish one to the other half. The following month he selects the names of the persons to whom he mailed the letter whose prediction turned out to be right, that is, 5,000 names. The next month he does the same with the remaining 2,500 names, until the list narrows down to 500 people. Of these there will be 200 victims. An investment in a few thousand dollars’ worth of postage stamps will turn into several million.',
        location: '2951-57',
        time: 'Thursday, May 06, 2021, 10:55 PM',
        favourite: false,
        deleted: false,
    },
    '0f3730e4-3320-5ac2-a826-07f391c3716c': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'A programmer helped me build a backtester. It is a software program connected to a database of historical prices, which allows me to check the hypothetical past performance of any trading rule of average complexity.',
        location: '3017-18',
        time: 'Thursday, May 06, 2021, 11:03 PM',
        favourite: false,
        deleted: false,
    },
    'd35c61d4-caee-559f-b795-f4d82548f96a': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'What am I doing? The exact same task of looking for the survivor within the set of rules that can possibly work. I am fitting the rule on the data. This activity is called data snooping. The more I try, the more I am likely, by mere luck, to find a rule that worked on past data. A random series will always present some detectable pattern. I am convinced that there exists a tradable security in the Western world that would be 100% correlated with the changes in temperature in Ulan Bator, Mongolia.',
        location: '3022-25',
        time: 'Thursday, May 06, 2021, 11:04 PM',
        favourite: false,
        deleted: false,
    },
    '6e569632-8e82-5f82-9246-6f6ef4cdc298': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'To get technical, there are even worse extensions. An outstanding recent paper by Sullivan, Timmerman, and White goes further and considers that the rules that may be in use successfully today may be the result of a survivorship ',
        location: '3026-27',
        time: 'Thursday, May 06, 2021, 11:04 PM',
        favourite: false,
        deleted: false,
    },
    '862a2595-4909-5c09-bede-25d33e56d197': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'One of my colleagues, a man with prestigious degrees, grew to believe in such a virtual world to the point of losing all sense of reality. Whether the modicum of common sense left in him might have rapidly vanished under the mounds of simulations, or whether he might have had none to engage in such pursuit, I cannot tell.',
        location: '3039-41',
        time: 'Thursday, May 06, 2021, 11:05 PM',
        favourite: false,
        deleted: false,
    },
    'f2c1f0fc-5efa-5bd2-8e82-a24810e604a7': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'I wonder if those “experts” who make foolish (and self-serving) statements like “markets will always go up in any twenty-year period” are aware of this problem.',
        location: '3067-69',
        time: 'Thursday, May 06, 2021, 11:09 PM',
        favourite: false,
        deleted: false,
    },
    '3b7bd31d-ce01-5af6-a79a-f85f220bf8b8': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Many of these claims have been harmless outside of the financial profits for these charlatans—but many cancer patients may have replaced the more scientifically investigated therapies, in favor of these methods, and died as a result of their neglecting more orthodox cures (again, the nonscientific methods are gathered under what is called “alternative medicine,” that is, unproven therapies, and the medical community has difficulties convincing the press that there is only one medicine and that alternative medicine is not medicine).',
        location: '3095-99',
        time: 'Thursday, May 06, 2021, 11:12 PM',
        favourite: false,
        deleted: false,
    },
    '34ba3beb-b213-579d-b47c-6c34bfce55f6': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'marred by a pernicious survivorship bias, affecting the way research gets published. In a way that is similar to journalism, research that yields no result does not make it to print. That',
        location: '3136-37',
        time: 'Thursday, May 06, 2021, 11:17 PM',
        favourite: false,
        deleted: false,
    },
    '1fbb9754-f5fe-5180-86f2-2d58fa8b864d': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The Polya process can be presented as follows: Assume an urn initially containing equal quantities of black and red balls. You are to guess each time which color you will pull out before you make the draw. Here the game is rigged. Unlike a conventional urn, the probability of guessing correctly depends on past success, as you get better or worse at guessing depending on past performance. Thus, the probability of winning increases after past wins, that of losing increases after past losses.',
        location: '3227-30',
        time: 'Thursday, May 06, 2021, 11:29 PM',
        favourite: false,
        deleted: false,
    },
    '827e1d56-8542-55ce-b14b-a9df103bad77': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'mathematics is merely a way of thinking and meditating, little more, in our world of randomness.',
        location: '3248',
        time: 'Thursday, May 06, 2021, 11:36 PM',
        favourite: false,
        deleted: false,
    },
    '73f42011-3ba5-50b6-87c0-310fb6bc7b87': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Studies of the dynamics of networks have mushroomed recently. They became popular with Malcolm Gladwell’s book The Tipping Point, in which he shows how some of the behaviors of variables such as epidemics spread extremely fast beyond some unspecified critical level.',
        location: '3249-51',
        time: 'Thursday, May 06, 2021, 11:37 PM',
        favourite: false,
        deleted: false,
    },
    'fb4cfc86-5d83-52b9-a3d0-31628f511bfd': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'As I am writing these lines, I am suddenly realizing that the world’s bipolarity is hitting me very hard. Either one succeeds wildly, by attracting all the cash, or fails to draw a single penny. Likewise with books. Either everyone wants to publish it, or nobody is interested in returning telephone calls',
        location: '3286-88',
        time: 'Thursday, May 06, 2021, 11:41 PM',
        favourite: false,
        deleted: false,
    },
    '01368d32-746f-55dd-a258-15fef6adc92f': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'A test of a disease presents a rate of 5% false positives. The disease strikes 1/1,000 of the population. People are tested at random, regardless of whether they are suspected of having the disease. A patient’s test is positive. What is the probability of the patient being stricken with the disease? Most doctors answered 95%, simply taking into account the fact that the test has a 95% accuracy rate. The answer is the conditional probability that the patient is sick and the test shows it—close to 2%. Less than one in five professionals got it right.',
        location: '3665-69',
        time: 'Saturday, May 08, 2021, 11:25 PM',
        favourite: false,
        deleted: false,
    },
    '23181e52-86d3-5524-89eb-789db25e7a48': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'People overvalue their knowledge and underestimate the probability of their being wrong.',
        location: '3718-19',
        time: 'Saturday, May 08, 2021, 11:37 PM',
        favourite: false,
        deleted: false,
    },
    'f1ecda07-d18e-5c93-8d34-2769e941a69f': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The epiphany I had in my career in randomness came when I understood that I was not intelligent enough, nor strong enough, to even try to fight my emotions. Besides, I believe that I need my emotions to formulate my ideas and get the energy to execute them.',
        location: '3843-44',
        time: 'Saturday, May 08, 2021, 11:52 PM',
        favourite: false,
        deleted: false,
    },
    'ea310397-61a2-572e-a7c4-0d332f522674': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'I am incapable of taming my emotions when reading their comments. The best I can do is just not read them. Likewise with journalists. Not reading their discussions of markets spares me plenty of emotional expenditure. I will do the same with unsolicited comments on this book. Wax in my ears.',
        location: '3865-67',
        time: 'Saturday, May 08, 2021, 11:56 PM',
        favourite: false,
        deleted: false,
    },
    '2dad365a-06ea-5388-af9c-2f1924390176': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Without tricks I would not escape the toxicity of the information age. In the trading room of my company, I have the television set turned on all day with the financial news channel CNBC staging commentator after commentator and CEO after CEO murdering rigor all day long. What is the trick? I have the volume turned completely off. Why? Because when the television set is silent, the babbling person looks ridiculous, exactly the opposite effect as when the sound is on. One sees a person with moving lips and contortions in his facial muscles, taking themselves seriously—but no sound comes out.',
        location: '3888-92',
        time: 'Saturday, May 08, 2021, 11:59 PM',
        favourite: false,
        deleted: false,
    },
    '94b0279d-60cb-5b03-94df-0e8f2fd5b0d4': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Television pundits lose their intimidating effect;they even look ridiculous. They seem to be excited about something terribly unimportant. Suddenly pundits become clowns, which is a reason the writer Graham Greene refused to go on television.',
        location: '3895-96',
        time: 'Sunday, May 09, 2021, 12:00 AM',
        favourite: false,
        deleted: false,
    },
    '5352b482-2e23-5c1b-bd91-541f5534f0de': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Yet I have experienced leaps of joy over results that I knew were mere noise, and bouts of unhappiness over results that did not carry the slightest degree of statistical significance. I cannot help it, but I am emotional and derive most of my energy from my emotions. So the solution does not reside in taming my heart.',
        location: '3995-98',
        time: 'Sunday, May 09, 2021, 01:00 PM',
        favourite: false,
        deleted: false,
    },
    '62f8dbb4-ebfc-528e-a2e1-54dce88c063b': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'One of the most irritating conversations I’ve had is with people who lecture me on how I should behave. Most of us know pretty much how we should behave. It is the execution that is the problem, not the absence of knowledge. I am tired of the moralizing slow-thinkers who pound me with platitudes like I should floss daily, eat my regular apple, and visit the gym outside of the New Year’s resolution. In the markets the recommendation would be to ignore the noise component in the performance. We need tricks to get us there but before that we need to accept the fact that we are mere animals in need of lower forms of tricks, not lectures.',
        location: '4001-6',
        time: 'Sunday, May 09, 2021, 01:01 PM',
        favourite: false,
        deleted: false,
    },
    'c73afa5f-558f-580d-812f-a17ee169108f': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Researchers found that purely rational behavior on the part of humans can come from a defect in the amygdala that blocks the emotions of attachment, meaning that the subject is, literally, a psychopath. Could Soros have a genetic flaw that makes him rational as a decision maker? Such trait of absence of marriage to ideas is indeed rare among humans.',
        location: '4105-8',
        time: 'Sunday, May 09, 2021, 05:56 PM',
        favourite: false,
        deleted: false,
    },
    '07f1aedb-c974-5171-b767-b33d95ae81fc': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The attribution bias has another effect: It gives people the illusion of being better at what they do, which explains the findings that 80 to 90% of people think that they are above the average (and the median) in many things.',
        location: '4161-62',
        time: 'Sunday, May 09, 2021, 06:03 PM',
        favourite: false,
        deleted: false,
    },
    '1d6afcab-3ade-5deb-8779-0af13578150b': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'While shaken with emotion. No stiff upper lip. There is nothing wrong and undignified with emotions—we are cut to have them. What is wrong is not following the heroic or, at least, the dignified path. That is what stoicism truly means. It is the attempt by man to get even with probability. I need not be nasty at all and break the spell of the poem and its message, but I cannot resist some cynicism.',
        location: '4214-17',
        time: 'Sunday, May 09, 2021, 06:08 PM',
        favourite: false,
        deleted: false,
    },
    'acb6698e-9600-5a49-acc8-3e442ba79ce9': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The stoic is a person who combines the qualities of wisdom, upright dealing, and courage. The stoic will thus be immune from life’s gyrations as he will be superior to the wounds from some of life’s dirty tricks.',
        location: '4223-24',
        time: 'Sunday, May 09, 2021, 06:10 PM',
        favourite: false,
        deleted: false,
    },
    '89a79d4e-927e-523e-910d-83da8fb02b16': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Seneca’s Letters from a Stoic, a soothing and surprisingly readable book that I distribute to my trader friends (Seneca also took his own life when cornered by destiny).',
        location: '4225-26',
        time: 'Sunday, May 09, 2021, 06:10 PM',
        favourite: false,
        deleted: false,
    },
    '8d81db4c-189d-5ce4-acbc-0c5921403d1c': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Recall that ideas do not truly sink in when emotions come into play; we do not use our rational brain outside of classrooms. Self-help books (even when they are not written by charlatans) are largely ineffectual. Good, enlightened (and “friendly”) advice and eloquent sermons do not register for more than a few moments when they go against our wiring. The interesting thing about stoicism is that it plays on dignity and personal aesthetics, which are part of our genes. Start stressing personal elegance at your next misfortune.',
        location: '4228-32',
        time: 'Sunday, May 09, 2021, 06:10 PM',
        favourite: false,
        deleted: false,
    },
    '78296578-66ab-5691-ad44-b72c3cc7fd43': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Dress at your best on your execution day (shave carefully); try to leave a good impression on the death squad by standing erect and proud. Try not to play victim when diagnosed with cancer (hide it from others and only share the information with the doctor—it will avert the platitudes and nobody will treat you like a victim worthy of their pity; in addition, the dignified attitude will make both defeat and victory feel equally heroic). Be extremely courteous to your assistant when you lose money (instead of taking it out on him as many of the traders whom I scorn routinely do). Try not to blame others for your fate, even if they deserve blame. Never exhibit any self-pity, even if your significant other bolts with the handsome ski instructor or the younger aspiring model. Do not complain. If you suffer from a benign version of the “attitude problem,” like one of my childhood friends, do not start playing nice guy if your business dries up (he sent a heroic e-mail to his colleagues informing them “less business, but same attitude”). The only article Lady Fortuna has no control over is your behavior. Good luck.',
        location: '4233-41',
        time: 'Sunday, May 09, 2021, 06:12 PM',
        favourite: false,
        deleted: false,
    },
    '973cbc97-c878-58be-96b5-c6ce142fe491': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Everything external to us—good or bad, desirable or undesirable—can be used for our education and benefit. We can learn from both pleasure and pain, happiness and misery, success and failure.',
        location: '102-4',
        time: 'Monday, May 10, 2021, 12:00 AM',
        favourite: false,
        deleted: false,
    },
    '2b85c767-33be-5210-bc3c-b98957c2a37f': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'It is not enough just to have a positive goal. As the above examples show, our goals must be in harmony with our thoughts—both conscious and unconscious—or they will never be realized.',
        location: '123-24',
        time: 'Monday, May 10, 2021, 12:04 AM',
        favourite: false,
        deleted: false,
    },
    '675f2193-df74-5f23-bf16-8572b90fed96': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'It assumes that some people are completely virtuous while others are completely evil, which is an oversimplification. Upon closer examination, the more dishonest entrepreneur may possess certain virtues—like decisiveness and efficiency—that the more honest one lacks. ',
        location: '128-30',
        time: 'Monday, May 10, 2021, 12:05 AM',
        favourite: false,
        deleted: false,
    },
    'ffd3d086-7f8a-578f-8897-31ae2abe025d': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'It soothes our vanity to say that we failed because we’re too good, or that we’re broke because we’re too honest. But this is not true. Until we have wiped every impure thought from our minds and cleansed every spot from our souls, how can we know that our sufferings are the result of our virtues and not our faults?',
        location: '130-32',
        time: 'Monday, May 10, 2021, 12:05 AM',
        favourite: false,
        deleted: false,
    },
    'a09fa781-b96a-58c2-a9c3-0d60e5b5d820': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Those who remain pure in their thoughts grow old gracefully and serenely. Their waning years are bathed in beautiful, mellow hues, like a sunset over the ocean. ',
        location: '180-81',
        time: 'Monday, May 10, 2021, 11:49 AM',
        favourite: false,
        deleted: false,
    },
    '1133b63a-8eb4-5b56-a074-b00267160f1c': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'There is no better medicine than cheerful thought for curing and preventing bodily ills. To wish the best for all people; to find the good in everything; to be content in all circumstances—such thoughts are the portal to heaven.',
        location: '183-84',
        time: 'Monday, May 10, 2021, 11:50 AM',
        favourite: false,
        deleted: false,
    },
    '4d20b458-c4f8-5064-992e-08a5271541ab': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Conceive a central purpose for your life and set out to accomplish it. It may be a noble ideal, such as spreading justice and freedom, or a worldly attainment, such as founding a successful business. Whatever it is, make it the focal point of your thoughts. Dedicate yourself to its realization. The power of thought multiplies through focus and concentration.',
        location: '191-93',
        time: 'Monday, May 10, 2021, 11:51 AM',
        favourite: false,
        deleted: false,
    },
    'a0477802-3efe-5f42-a885-cab010897c99': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Concentrate on doing your job as well as you can, however insignificant it may seem. This will develop and expand your ability to take on greater goals in the future.',
        location: '197-98',
        time: 'Monday, May 10, 2021, 11:52 AM',
        favourite: false,
        deleted: false,
    },
    'e8d1b4f3-a154-5aeb-bd3e-d3e8057b17cc': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Successful people put off gratification. They conceive goals, create plans, and execute those plans. They act independently, on their own initiative. They master their minds, and so master their circumstances.',
        location: '222-24',
        time: 'Monday, May 10, 2021, 11:54 AM',
        favourite: false,
        deleted: false,
    },
    '0605e30b-66ee-5f32-86ac-8ba7bf57df4f': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'The higher you elevate your thoughts—expanding from desiring your own good, to the good of your family and friends, to the good of all people, to the good of the whole earth and all creatures—the greater will be your success, and the more enduring your achievements.',
        location: '224-26',
        time: 'Monday, May 10, 2021, 11:55 AM',
        favourite: false,
        deleted: false,
    },
    'be19b692-d23c-5a2d-8e1b-5fbbf0a8eb6a': {
        raw: 'asamanthinketh:21stcenturyedition',
        book: 'As a Man Thinketh: 21st Century Edition ',
        author: 'Charles Conrad',
        quote: 'Those who give little accomplish little; those who give much accomplish much; those who give the most become the greatest.',
        location: '232-33',
        time: 'Monday, May 10, 2021, 11:56 AM',
        favourite: false,
        deleted: false,
    },
    'c77dd055-8a3f-5789-adfd-896a3de8141c': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'There seems to be some evidence that conversations and correspondence with intelligent people is a better engine for personal edification than plain library-ratting (human warmth: Something in our nature may help us grow ideas while dealing and socializing with other people).',
        location: '4264-65',
        time: 'Monday, May 10, 2021, 12:04 PM',
        favourite: false,
        deleted: false,
    },
    '15252027-df5f-5c55-a33f-284258fca9ec': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The higher up the corporate ladder, the higher the compensation to the individual. This might be justified, as it makes plenty of sense to pay individuals according to their contributions. However, and in general (provided we exclude risk-bearing entrepreneurs), the higher up the corporate ladder, the lower the evidence of such contribution. I call this the inverse rule.',
        location: '4330-33',
        time: 'Monday, May 10, 2021, 12:10 PM',
        favourite: false,
        deleted: false,
    },
    '5f48c77e-8bf7-5785-98e7-a1c7b8519aea': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'More appropriately, what they have is skill in getting promoted within a company rather than pure skills in making optimal decisions—we call that “corporate political skill.” These are people mostly trained at using PowerPoint presentations.',
        location: '4362-63',
        time: 'Monday, May 10, 2021, 12:14 PM',
        favourite: false,
        deleted: false,
    },
    '06d56de6-d8e9-5135-b86d-3be206868db3': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'Uncertainty protects them from themselves. Chapter 10 showed, with the illustration of Buridan’s donkey, that randomness is not always unwelcome. This discussion aims to show how some degree of unpredictability (or lack of knowledge) can be beneficial to our defective species. A slightly random schedule prevents us from optimizing and being exceedingly efficient, particularly in the wrong things. This little bit of uncertainty might make the diner relax and forget the time pressures. He would be forced to act as a satisficer instead of a maximizer (Chapter 11 discussed Simon’s satisficing as a blend of satisfying and maximizing)—research on happiness shows that those who live under a self-imposed pressure to be optimal in their enjoyment of things suffer a measure of distress.',
        location: '4399-4405',
        time: 'Monday, May 10, 2021, 12:19 PM',
        favourite: false,
        deleted: false,
    },
    '11590d24-5d7d-5859-848c-51597c10f86f': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The difference between satisficers and optimizers raises a few questions. We know that people of a happy disposition tend to be of the satisficing kind, with a set idea of what they want in life and an ability to stop upon gaining satisfaction. Their goals and desires do not move along with the experiences. They do not tend to experience the internal treadmill effects of constantly trying to improve on their consumption of goods by seeking higher and higher levels of sophistication. In other words, they are neither avaricious nor insatiable. An optimizer, by comparison, is the kind of person who will uproot himself and change his official residence just to reduce his tax bill by a few percentage points. (You would think that the entire point of a higher income is to be free to choose where to live; in fact it seems, for these people, wealth causes them to increase their dependence!) Getting rich results in his seeing flaws in the goods and services he buys. The coffee is not warm enough. The cook no longer deserves the three stars given to him by the Michelin guide (he will write to the editors).The table is too far from the window. People who get promoted to important positions usually suffer from tightness of schedules: Everything has an allotted time. When they travel, everything is “organized” with optimizing intent, including lunch at 12:45 with the president of the company (a table not too far from the window), the Stairmaster at 4:40, and opera at 8:00. Causality is not clear: The question remains whether optimizers are unhappy because they are constantly seeking a better deal or if unhappy people tend to optimize out of their misery. In any case, randomness seems to operate either as a cure or as Novocain!',
        location: '4406-18',
        time: 'Monday, May 10, 2021, 12:20 PM',
        favourite: false,
        deleted: false,
    },
    '76e4f056-d583-58ce-bd71-eb703153a6d6': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'People around me are either students or retired persons, or those who do not have “important appointments,” hence not afraid of what they call wasted time. To go from Munich to Milan, I picked the seven-and-a-half-hour train instead of the plane, which no self-respecting businessperson would do on a weekday, and am enjoying an air unpolluted by persons squeezed by life. I came to this conclusion when, about a decade ago, I stopped using an alarm clock. I still woke up around the same time, but I followed my own personal clock. A dozen minutes of fuzziness and variability in my schedule made a considerable difference. True, there are some activities that require such dependability that an alarm clock is necessary, but I am free to choose a profession where I am not a slave to external pressure. Living like this, one can also go to bed early and not optimize one’s schedule by squeezing every minute out of one’s evening. At the limit, you can decide whether to be (relatively) poor, but free of your time, or rich but as dependent as a slave.',
        location: '4422-29',
        time: 'Monday, May 10, 2021, 12:22 PM',
        favourite: false,
        deleted: false,
    },
    'ed60ed11-40c7-533c-a4af-23397aa439c5': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'It took me a while to figure out that we are not designed for schedules. The realization came when I recognized the difference between writing a paper and writing a book. Books are fun to write, papers are painful. I tend to find the activity of writing greatly entertaining, given that I do it without any external constraint. You write, and may interrupt your activity, even in mid-sentence, the second it stops being attractive. After the success of this book, I was asked to write papers by the editors of a variety of professional and scientific journals. Then they asked me how long the piece should be. What? How long? For the first time in my life, I experienced a loss of pleasure in writing! Then I figured out a personal rule: For writing to be agreeable to me, the length of the piece needs to remain unpredictable. If I see the end of it, or if I am subjected to the shadow of an outline, I give up. I repeat that our ancestors were not subjected to outlines, schedules, and administrative deadlines.',
        location: '4430-36',
        time: 'Monday, May 10, 2021, 12:23 PM',
        favourite: false,
        deleted: false,
    },
    '09047c2c-2a22-5bbf-ab50-3b7f576d123c': {
        raw: 'fooledbyrandomness:thehiddenroleofchanceinlifeandinthemarkets',
        book: 'Fooled by Randomness: The Hidden Role of Chance in Life and in the Markets ',
        author: 'Nassim Nicholas Taleb',
        quote: 'The millionaire mind: Stanley (2000). He also figured out (correctly) that the rich were “risk takers” and inferred (incorrectly) that risk taking made one rich. Had he examined the population of failed entrepreneurs he would have also inferred (correctly) that the failed entrepreneurs too were “risk takers.”',
        location: '4535-38',
        time: 'Monday, May 10, 2021, 12:29 PM',
        favourite: false,
        deleted: false,
    },
    '553740ff-6966-5a46-9c7d-41fdc98077a8': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: '“My experience has taught me this about how people and organizations improve: the best place to look is for small changes we could make in the things we do often. There is power in steadiness and repetition.”6',
        location: '2219-20',
        time: 'Monday, May 10, 2021, 12:44 PM',
        favourite: false,
        deleted: false,
    },
    '3f370001-ecc2-51c2-8bdd-072116d74872': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'So we introduced a token system.9 The children were given ten tokens at the beginning of the week. These could each be traded in for either thirty minutes of screen time or fifty cents at the end of the week, adding up to $5 or five hours of screen time a week. If a child read a book for thirty minutes, he or she would earn an additional token, which could also be traded in for screen time or for money.',
        location: '2240-44',
        time: 'Monday, May 10, 2021, 12:48 PM',
        favourite: false,
        deleted: false,
    },
    '2e1a2790-59f2-5667-8c59-625b14198f2a': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'A popular idea in Silicon Valley is “Done is better than perfect.”10 The sentiment is not that we should produce rubbish. The idea, as I read it, is not to waste time on nonessentials and just to get the thing done. In entrepreneurial circles the idea is expressed as creating a “minimal viable product.”',
        location: '2249-51',
        time: 'Monday, May 10, 2021, 12:49 PM',
        favourite: false,
        deleted: false,
    },
    '228400e8-f95d-56b1-9f62-67fd2af7e78c': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'A popular idea in Silicon Valley is “Done is better than perfect.”10 The sentiment is not that we should produce rubbish. The idea, as I read it, is not to waste time on nonessentials and just to get the thing done. In entrepreneurial circles the idea is expressed as creating a “minimal viable product.”11 The idea is, “What is the simplest possible product that will be useful and valuable to the intended customer?”',
        location: '2249-52',
        time: 'Monday, May 10, 2021, 12:49 PM',
        favourite: false,
        deleted: false,
    },
    '821b13cc-16b1-57c6-a294-fb4628bb1cef': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'There are two opposing ways to approach an important goal or deadline. You can start early and small or start late and big. “Late and big” means doing it all at the last minute: pulling an all-nighter and “making it happen.” “Early and small” means starting at the earliest possible moment with the minimal possible time investment. Often just ten minutes invested in a project or assignment two weeks before it is due can save you much frantic and stressed-out scrambling at the eleventh hour. Take a goal or deadline you have coming up and ask yourself, “What is the minimal amount I could do right now to prepare?”',
        location: '2264-68',
        time: 'Monday, May 10, 2021, 12:51 PM',
        favourite: false,
        deleted: false,
    },
    '354148cc-c4ff-5808-af38-f4d9d6f88009': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'One leader who is an exceptionally inspiring speaker has explained that the key for him is to start to prepare his big speeches six months before he does them. He isn’t preparing the entire speech; he just starts. If you have a big presentation coming up over the next few weeks or months, open a file right now and spend four minutes starting to put down any ideas.',
        location: '2268-71',
        time: 'Monday, May 10, 2021, 12:51 PM',
        favourite: false,
        deleted: false,
    },
    '0b5553e4-da7d-5c30-b98c-c0a2bdf5cae1': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'Without being fully aware, we can get caught in nonessential habits—like checking our e-mail the second we get out of bed every morning, or picking up a doughnut on the way home from work each day, or spending our lunch hour trolling the Internet instead of using the time to think, reflect, recharge, or connect with friends and colleagues. So how can we discard the routines that keep us locked in nonessential habits and replace them with routines that make executing essentials almost effortless?',
        location: '2366-70',
        time: 'Monday, May 10, 2021, 01:10 PM',
        favourite: false,
        deleted: false,
    },
    'a3c65435-e0ff-5a93-8d24-e72e8af483b2': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'Do you ever find yourself busy trying to mentally prepare for the next meeting, or the next assignment, or the next chapter in your life, rather than being fully present in the current one? It’s natural and human to obsess over past mistakes or feel stress about what may be ahead of us. Yet every second spent worrying about a past or future moment distracts us from what is important in the here and now.',
        location: '2451-54',
        time: 'Monday, May 10, 2021, 01:20 PM',
        favourite: false,
        deleted: false,
    },
    'db1bbca5-758c-5044-a6fe-ca39426a7b99': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'Nonessentialists tend to be so preoccupied with past successes and failures, as well as future challenges and opportunities, that they miss the present moment. They become distracted. Unfocused. They aren’t really there.',
        location: '2461-62',
        time: 'Monday, May 10, 2021, 01:21 PM',
        favourite: false,
        deleted: false,
    },
    '572be2db-aa3d-5e2c-b251-8eef701eaafc': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'What we can’t do is concentrate on two things at the same time. When I talk about being present, I’m not talking about doing only one thing at a time. I’m talking about being focused on one thing at a time. Multitasking itself is not the enemy of Essentialism; pretending we can “multifocus” is.',
        location: '2499-2501',
        time: 'Monday, May 10, 2021, 01:25 PM',
        favourite: false,
        deleted: false,
    },
    '89075e5f-4dd6-5305-a655-8f732cf83ad4': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: '“In work, do what you enjoy. In family life, be completely present.”',
        location: '2536',
        time: 'Monday, May 10, 2021, 01:30 PM',
        favourite: false,
        deleted: false,
    },
    'd57996fe-07d7-5a46-8071-4036c99cdb07': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: '“I do believe in simplicity. It is astonishing as well as sad, how many trivial affairs even the wisest thinks he must attend to in a day; … so simplify the problem of life, distinguish the necessary and the real”).',
        location: '2585-86',
        time: 'Tuesday, May 11, 2021, 11:08 AM',
        favourite: false,
        deleted: false,
    },
    '0c4619d4-c09d-51c6-b0d3-114191f303c4': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'Once you become an Essentialist, you will find that you aren’t like everybody else. When other people are saying yes, you will find yourself saying no. When other people are doing, you will find yourself thinking. When other people are speaking, you will find yourself listening. When other people are in the spotlight, vying for attention, you will find yourself waiting on the sidelines until it is time to shine. While other people are padding their résumés and building out their LinkedIn profiles, you will be building a career of meaning. While other people are complaining (read: bragging) about how busy they are, you will just be smiling sympathetically, unable to relate. While other people are living a life of stress and chaos, you will be living a life of impact and fulfillment. In many ways, to live as an Essentialist in our too-many-things-all-the-time society is an act of quiet revolution.',
        location: '2618-24',
        time: 'Tuesday, May 11, 2021, 11:12 AM',
        favourite: false,
        deleted: false,
    },
    '3870a5b3-adef-5883-b585-3970644a75f2': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'You will feel less and less a function of other people’s to-do lists and agendas. Remember that if you don’t prioritize your life someone else will. But if you are determined to prioritize your own life you can. The power is yours.',
        location: '2671-73',
        time: 'Tuesday, May 11, 2021, 11:17 AM',
        favourite: false,
        deleted: false,
    },
    'f5e3b1ed-619b-5d0d-b510-1bf2f404b588': {
        raw: 'essentialism:thedisciplinedpursuitofless',
        book: 'Essentialism: The Disciplined Pursuit of Less ',
        author: 'Greg Mckeown',
        quote: 'Checks in with people in a gentle way to see how he or she can remove obstacles and enable small wins.',
        location: '2742',
        time: 'Tuesday, May 11, 2021, 11:23 AM',
        favourite: false,
        deleted: false,
    },
    '39874519-ed31-5ffe-b8ac-ce12a3934c55': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Humans act according to their desires. If by ‘free will’ you mean the ability to act according to your desires – then yes, humans have free will, and so do chimpanzees, dogs and parrots.',
        location: '4207-8',
        time: 'Tuesday, May 11, 2021, 11:33 AM',
        favourite: false,
        deleted: false,
    },
    '334724e0-8cd9-552c-8067-a9f43582857a': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'But the million-dollar question is not whether parrots and humans can act out their inner desires – the question is whether they can choose their desires in the first place.',
        location: '4208-10',
        time: 'Tuesday, May 11, 2021, 11:33 AM',
        favourite: false,
        deleted: false,
    },
    '4cb87a68-999e-5567-83c2-dd853287f1b3': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Yet people erroneously jump to the conclusion that if I want to press it, I choose to want to. This is of course false. I don’t choose my desires. I only feel them, and act accordingly.',
        location: '4227-28',
        time: 'Tuesday, May 11, 2021, 11:36 AM',
        favourite: false,
        deleted: false,
    },
    '6a748421-3830-5e4b-9c56-5782cfaacad8': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Christian, Muslim and Jewish theologians debated for centuries the relations between the soul and the will. They assumed that every human has an internal inner essence – called the soul – which is my true self. They further maintained that this self possesses various desires, just as it possesses clothes, vehicles and houses. I allegedly choose my desires in the same way I choose my clothes, and my fate is determined according to these choices. If I choose good desires, I go to heaven. If I choose bad desires, I am sent to hell. The question then arose, how exactly do I choose my desires?',
        location: '4229-33',
        time: 'Tuesday, May 11, 2021, 11:37 AM',
        favourite: false,
        deleted: false,
    },
    'ec88275a-76d1-5486-921d-31b423595b0d': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Doubting free will is not just a philosophical exercise. It has practical implications. If organisms indeed lack free will, it implies we could manipulate and even control their desires using drugs, genetic engineering or direct brain stimulation.',
        location: '4243-44',
        time: 'Tuesday, May 11, 2021, 11:38 AM',
        favourite: false,
        deleted: false,
    },
    'c4fdcfac-fe84-540d-bb40-c4d6b9408960': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Experiments performed on Homo sapiens indicate that like rats humans too can be manipulated, and that it is possible to create or annihilate even complex feelings such as love, anger, fear and depression by stimulating the right spots in the human brain. The US military has recently initiated experiments on implanting computer chips in people’s brains, hoping to use this method to treat soldiers suffering from post-traumatic stress disorder.4 In Hadassah Hospital in Jerusalem, doctors have pioneered a novel treatment for patients suffering from acute depression. They implant electrodes into the patient’s brain, and wire the electrodes to a minuscule computer implanted into the patient’s breast. On receiving a command from the computer, the electrodes use weak electric currents to paralyse the brain area responsible for the depression.',
        location: '4261-67',
        time: 'Tuesday, May 11, 2021, 11:40 AM',
        favourite: false,
        deleted: false,
    },
    'f310ec5a-ea59-59c1-9403-452dd6b7a877': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'The narrating self doesn’t aggregate experiences – it averages them.',
        location: '4414-15',
        time: 'Thursday, May 13, 2021, 10:50 PM',
        favourite: false,
        deleted: false,
    },
    '82d77371-65eb-5504-a69d-cea9c8c75422': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'I discovered the fact that almost all great leaders in a wide range of fields wake up early in the morning. From Richard Branson to Tim Cook to Howard Schultz to Oprah Winfrey to Michael Phelps and Serena Williams, they all are early risers, mostly waking up before 6 a.m.',
        location: '115-17',
        time: 'Thursday, May 13, 2021, 11:15 PM',
        favourite: false,
        deleted: false,
    },
    '0b74436b-b84c-50fb-81c9-cfcbe6a47b0c': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'As a general idea, the American Academy of Sleep Medicine suggests: think of the bedroom as a cave; it should be cool, quiet and dark.',
        location: '194',
        time: 'Thursday, May 13, 2021, 11:24 PM',
        favourite: false,
        deleted: false,
    },
    '626a2562-3b6e-5189-84f8-23391933013e': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: '‘There is no need to go to India or anywhere else to find peace. You will find that deep place of silence right in your room, your garden or even your bathtub.’ —Elisabeth Kübler-Ross',
        location: '420-22',
        time: 'Friday, May 14, 2021, 11:56 PM',
        favourite: false,
        deleted: false,
    },
    '72e4cc44-4665-508a-ba4a-599f108e1b40': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'Most people who have trouble sleeping or experience restless sleep have completely counterproductive bedtime habits like watching news or highly stimulating shows on TV just before bed, trying to solve the unfinished businesses from that day or going to bed feeling upset about a conversation with their spouse or their child.',
        location: '452-54',
        time: 'Friday, May 14, 2021, 11:59 PM',
        favourite: false,
        deleted: false,
    },
    '1023ce5b-965a-5c82-8b36-b5a391c95af3': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'It’s very important that you avoid looking at the watch during all this time because doing so will stress you out even more.',
        location: '515-16',
        time: 'Saturday, May 15, 2021, 12:05 AM',
        favourite: false,
        deleted: false,
    },
    'a77d3c03-bd96-53a5-b14a-fc130c81dc04': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'They HAD TO wake up…but I WANTED TO wake up. It’s a big difference in the sense that ‘when you don’t have a choice…it’s easy’.',
        location: '859-60',
        time: 'Sunday, May 16, 2021, 11:13 PM',
        favourite: false,
        deleted: false,
    },
    '98a012f0-0b95-5958-a466-85f383e6fac4': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: '3.    Your main focus is to finish the uncompleted tasks or projects and go to bed. This is essentially a reactive energy that is looking to mainly ‘land the plane’. But making personal changes require a different type of energy—a proactive one that is about ‘taking off’ and initiating new behaviour.',
        location: '885-87',
        time: 'Sunday, May 16, 2021, 11:17 PM',
        favourite: false,
        deleted: false,
    },
    '6766b752-4312-5b16-b0f3-7729ee8fecf4': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: '1.  Humans will lose their economic and military usefulness, hence the economic and political system will stop attaching much value to them. 2.  The system will still find value in humans collectively, but not in unique individuals. 3.  The system will still find value in some unique individuals, but these will be a new elite of upgraded superhumans rather than the mass of the population.',
        location: '4554-57',
        time: 'Sunday, May 16, 2021, 11:55 PM',
        favourite: false,
        deleted: false,
    },
    'c37b339d-3a5b-5987-b65c-40d425afd53e': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'A similar rationale stood behind the enfranchisement of women in the wake of the First World War. Realising the vital role of women in total industrial wars, countries saw the need to give them political rights in peacetime. Thus in 1918 President Woodrow Wilson became a supporter of women’s suffrage, explaining to the US Senate that the First World War ‘could not have been fought, either by the other nations engaged or by America, if it had not been for the services of women – services rendered in every sphere – not only in the fields of effort in which we have been accustomed to see them work, but wherever men have worked and upon the very skirts and edges of the battle itself.',
        location: '4579-84',
        time: 'Sunday, May 16, 2021, 11:58 PM',
        favourite: false,
        deleted: false,
    },
    'c733ebaa-4a10-54d9-a96a-69dfc14e9398': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'The most advanced armies of the twenty-first century rely far more on cutting-edge technology. Instead of limitless cannon fodder, you now need only small numbers of highly trained soldiers, even smaller numbers of special forces super-warriors and a handful of experts who know how to produce and use sophisticated technology. Hi-tech forces ‘manned’ by pilotless drones and cyber-worms are replacing the mass armies of the twentieth century, and generals delegate more and more critical decisions to algorithms.',
        location: '4587-90',
        time: 'Sunday, May 16, 2021, 11:58 PM',
        favourite: false,
        deleted: false,
    },
    '1676f1c0-10d2-5601-a583-a7e5311c0104': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'However, we are on the brink of a momentous revolution. Humans are in danger of losing their value, because intelligence is decoupling from consciousness.',
        location: '4615-16',
        time: 'Monday, May 17, 2021, 12:02 AM',
        favourite: false,
        deleted: false,
    },
    '52d68906-d765-52e4-a379-cccd47682afe': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Indeed, if we forbid humans to drive taxis and cars altogether, and give computer algorithms monopoly over traffic, we can then connect all vehicles to a single network, and thereby make car accidents virtually impossible.',
        location: '4629-31',
        time: 'Monday, May 17, 2021, 12:04 AM',
        favourite: false,
        deleted: false,
    },
    'a3c09406-7fcd-5c63-842c-934b4231a870': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Bank clerks and travel agents, who a short time ago were completely secure from automation, have become endangered species. How many travel agents do we need when we can use our smartphones to buy plane tickets from an algorithm?',
        location: '4638-40',
        time: 'Monday, May 17, 2021, 12:05 AM',
        favourite: false,
        deleted: false,
    },
    'e5f69285-2034-5600-a60a-2cf32b61ca55': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Secondly, Watson can be intimately familiar not only with my entire genome and my day-to-day medical history, but also with the genomes and medical histories of my parents, siblings, cousins, neighbours and friends. Watson will know instantly whether I visited a tropical country recently, whether I have recurring stomach infections, whether there have been cases of intestinal cancer in my family or whether people all over town are complaining this morning about diarrhoea.',
        location: '4684-87',
        time: 'Monday, May 17, 2021, 11:36 PM',
        favourite: false,
        deleted: false,
    },
    'f4e3b387-bcdb-5d9a-830e-a8e61f14729e': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Until a short time ago, facial recognition was a favourite example of something which even babies accomplish easily but which escaped even the most powerful computers on earth. Today facial-recognition programs are able to recognise people far more efficiently and quickly than humans can.',
        location: '4745-47',
        time: 'Monday, May 17, 2021, 11:44 PM',
        favourite: false,
        deleted: false,
    },
    '07f9e51d-d573-5118-9342-af6b2d8366c8': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Computer algorithms have recently proven their worth in ball games, too. For many decades, baseball teams used the wisdom, experience and gut instincts of professional scouts and managers to pick players. The best players fetched millions of dollars, and naturally enough the rich teams got the cream of the market, whereas poorer teams had to settle for the scraps. In 2002 Billy Beane, the manager of the low-budget Oakland Athletics, decided to beat the system. He relied on an arcane computer algorithm developed by economists and computer geeks to create a winning team from players that human scouts overlooked or undervalued. The old-timers were incensed by Beane’s algorithm transgressing into the hallowed halls of baseball. They said that picking baseball players is an art, and that only humans with an intimate and long-standing experience of the game can master it. A computer program could never do it, because it could never decipher the secrets and the spirit of baseball.',
        location: '4760-67',
        time: 'Monday, May 17, 2021, 11:47 PM',
        favourite: false,
        deleted: false,
    },
    'dad5da71-29d7-5d91-96ec-d8720d049e60': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Examining VITAL’s record so far, it seems that it has already picked up one managerial vice: nepotism. It has recommended investing in companies that grant algorithms more authority. With VITAL’s blessing, Deep Knowledge Ventures has recently invested in Silico Medicine, which develops computer-assisted methods for drug research, and in Pathway Pharmaceuticals, which employs a platform called OncoFinder to select and rate personalised cancer therapies.17',
        location: '4788-92',
        time: 'Tuesday, May 18, 2021, 03:51 PM',
        favourite: false,
        deleted: false,
    },
    '93680745-0dc4-57ec-9229-19bda3fb506f': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'If such algorithms consistently outperform human fund managers, we might end up with an algorithmic upper class owning most of our planet. This may sound impossible, but before dismissing the idea, remember that most of our planet is already legally owned by non-human inter-subjective entities, namely nations and corporations. Indeed, 5,000 years ago much of Sumer was owned by imaginary gods such as Enki and Inanna. If gods can possess land and employ people, why not algorithms?',
        location: '4799-4803',
        time: 'Tuesday, May 18, 2021, 03:52 PM',
        favourite: false,
        deleted: false,
    },
    '984cd83a-0fe1-5b08-96e9-33f38eb3cece': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'what will keep them occupied and content? People must do something, or they will go crazy. What will they do all day? One solution might be offered by drugs and computer games. Unnecessary people might spend increasing amounts of time within 3D virtual-reality worlds, which would provide them with far more excitement and emotional engagement than the drab reality outside. Yet such a development would deal a mortal blow to the liberal belief in the sacredness of human life and of human experiences. What’s so sacred in useless bums who pass their days devouring artificial experiences in La La Land?',
        location: '4852-56',
        time: 'Tuesday, May 18, 2021, 03:59 PM',
        favourite: false,
        deleted: false,
    },
    'c1f09fad-d622-50fb-ba98-7a9ed3065cf7': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'For liberalism believes not just in the value of human beings – it also believes in individualism. The second threat facing liberalism is that in the future, while the system might still need humans, it will not need individuals. Humans will continue to compose music, to teach physics and to invest money, but the system will understand these humans better than they understand themselves, and will make most of the important decisions for them.',
        location: '4869-72',
        time: 'Tuesday, May 18, 2021, 04:00 PM',
        favourite: false,
        deleted: false,
    },
    '5c82d58a-9dd4-5a00-ad43-d2f492d6551f': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Humanism fantasises about old age as a period of wisdom and awareness. The ideal elder may suffer from bodily ailments and weaknesses, but his mind is quick and sharp, and he has eighty years of insights to dispense. He knows exactly what’s what, and always has good advice for the grandchildren and other visitors. Twenty-first-century octogenarians don’t always look like that. Thanks to our growing understanding of human biology, medicine keeps us alive long enough for our minds and our ‘authentic selves’ to disintegrate and dissolve.',
        location: '4936-40',
        time: 'Tuesday, May 18, 2021, 05:45 PM',
        favourite: false,
        deleted: false,
    },
    '7fcbffbf-845b-5bf0-ba0d-976029ba621f': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Liberal habits such as democratic elections will become obsolete, because Google will be able to represent even my own political opinions better than myself. When I stand behind the curtain in the polling booth, liberalism instructs me to consult my authentic self, and choose whichever party or candidate reflects my deepest desires. Yet the life sciences point out that when I stand there behind the curtain, I don’t really remember everything I felt and thought in the years since the last election. Moreover, I am bombarded by a barrage of propaganda, spin and random memories which might well distort my choices. Just as in Kahneman’s cold-water experiment, in politics too the narrating self follows the peak-end rule. It forgets the vast majority of events, remembers only a few extreme incidents and gives a wholly disproportional weight to recent happenings.',
        location: '5039-45',
        time: 'Tuesday, May 18, 2021, 05:56 PM',
        favourite: false,
        deleted: false,
    },
    'b9c40441-0244-5e5c-8808-c30b9d19852d': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Google will also know that illness makes voters lean a bit more to the right than usual, and will compensate for this. Google will therefore be able to vote not according to my momentary state of mind, and not according to the fantasies of the narrating self, but rather according to the real feelings and interests of the collection of biochemical algorithms known as ‘I’.',
        location: '5054-56',
        time: 'Tuesday, May 18, 2021, 05:58 PM',
        favourite: false,
        deleted: false,
    },
    '3abd84d9-6aa9-54e9-b3ac-5b91f25722ab': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Liberalism will collapse on the day the system knows me better than I know myself. Which is less difficult than it may sound, given that most people don’t really know themselves well.',
        location: '5060-61',
        time: 'Tuesday, May 18, 2021, 05:58 PM',
        favourite: false,
        deleted: false,
    },
    '73c2b867-e077-59a5-b49c-1923bf0b9c24': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: '‘People might abandon their own psychological judgements and rely on computers when making important life decisions, such as choosing activities, career paths, or even romantic partners. It is possible that such data-driven decisions will improve people’s lives.’32',
        location: '5071-74',
        time: 'Tuesday, May 18, 2021, 06:00 PM',
        favourite: false,
        deleted: false,
    },
    '0246266a-6364-5ea9-9d39-f881b5df8823': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'In the twenty-first century our personal data is probably the most valuable resource most humans still have to offer, and we are giving it to the tech giants in exchange for email services and funny cat videos. From',
        location: '5079-81',
        time: 'Tuesday, May 18, 2021, 06:01 PM',
        favourite: false,
        deleted: false,
    },
    'fac6345e-3d5c-5a3f-a7da-f18c847d9cb0': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'From a liberal perspective, it is perfectly all right that one person is a billionaire living in a sumptuous chateau, whereas another is a poor peasant living in a straw hut. For according to liberalism, the peasant’s unique experiences are still just as valuable as the billionaire’s. That’s why liberal authors write long novels about the experiences of poor peasants – and why even billionaires read such books avidly.',
        location: '5167-70',
        time: 'Tuesday, May 18, 2021, 06:13 PM',
        favourite: false,
        deleted: false,
    },
    'c0a7e675-90e5-5c0d-8417-d6e5f79ade90': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'The liberal solution for social inequality is to give equal value to different human experiences, instead of trying to create the same experiences for everyone. However, what will be the fate of this solution once rich and poor are separated not merely by wealth, but also by real biological gaps?',
        location: '5173-75',
        time: 'Tuesday, May 18, 2021, 06:13 PM',
        favourite: false,
        deleted: false,
    },
    '90acd632-12b7-5b58-acbe-13e394ce88b2': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'The great human projects of the twentieth century – overcoming famine, plague and war – aimed to safeguard a universal norm of abundance, health and peace for all people without exception. The new projects of the twenty-first century – gaining immortality, bliss and divinity – also hope to serve the whole of humankind.',
        location: '5219-21',
        time: 'Tuesday, May 18, 2021, 06:21 PM',
        favourite: false,
        deleted: false,
    },
    '57f51b45-e740-54ab-893b-cc2acc55ac5a': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'You can take these sentences, turn them into fill-in-the-blank exercises, add a few pictures, and learn a bunch of new words and word forms. The word Where, for instance, might go with _____ are you going? I’m going to France!',
        location: '2418-20',
        time: 'Tuesday, May 18, 2021, 06:25 PM',
        favourite: false,
        deleted: false,
    },
    'd2f6d780-eb2e-52c0-ae97-b04e7d42f7bf': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'But if you want to learn a heap of grammar at the same time, write out your own examples and definitions. After you get your corrections, you can use the example sentences to teach you your words and use the corrections to teach you your grammar.',
        location: '2446-48',
        time: 'Tuesday, May 18, 2021, 06:28 PM',
        favourite: false,
        deleted: false,
    },
    'dc2333e5-115a-537c-8ec3-012c322b5303': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'For your very first book, try to find a familiar story—a translation of something you’ve already read or a book that’s been turned into a movie you’ve seen—and read it along with an audiobook. The audiobook will carry you along and help you read faster than you otherwise would. You won’t have the time to get bogged down with unknown words, and you’ll pick up the rhythm of the spoken language.',
        location: '2509-12',
        time: 'Tuesday, May 18, 2021, 06:34 PM',
        favourite: false,
        deleted: false,
    },
    '887102ea-8a18-5b07-8dc3-eba7cc604fc9': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: '• Reading without a dictionary is the simplest, easiest way to grow your passive vocabulary. On average, a single book will teach you three hundred to five hundred words from context alone. By reading just one book in your target language, you’ll make all future books and texts of any kind much easier to read.',
        location: '2523-26',
        time: 'Tuesday, May 18, 2021, 06:35 PM',
        favourite: false,
        deleted: false,
    },
    '4ca3dcbb-c9ba-526d-bc5b-bc8136ddc206': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: '• By reading in conjunction with an audiobook, you’ll have a much easier time moving through a long text, and you’ll pick up invaluable exposure to the rhythms of your language in action. This will improve your pronunciation, your listening comprehension, your vocabulary, your grammar; in short, it will provide a huge boost to every aspect of your language.',
        location: '2526-29',
        time: 'Tuesday, May 18, 2021, 06:35 PM',
        favourite: false,
        deleted: false,
    },
    '59f0142c-8221-5245-8168-fb3b1f4169dc': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'If you want to understand real-world speech, you need to listen to real-world speech. But you can’t just start by listening to foreign news radio all day. It’s just too hard. You’ll get frustrated and you’ll tune out, at which point you might as well be listening to random noise.',
        location: '2540-42',
        time: 'Tuesday, May 18, 2021, 06:36 PM',
        favourite: false,
        deleted: false,
    },
    'b2f9b3b9-6c3d-533a-91b1-6889d038ca66': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Instead, watch movies and television. In these genres, you are listening for the stories, and so you’ll pay very careful attention to everything you hear.',
        location: '2548-49',
        time: 'Tuesday, May 18, 2021, 06:37 PM',
        favourite: false,
        deleted: false,
    },
    'f2ed8fbe-a3f9-5f27-bd9c-2749b62cdb03': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'DVDs of movies and TV shows often come with subtitles in English or your target language. Don’t use them. The problem with subtitles is that reading is easier than listening. We learn with our eyes more than our ears, and so when subtitles are present, we don’t improve at listening.',
        location: '2551-53',
        time: 'Tuesday, May 18, 2021, 06:37 PM',
        favourite: false,
        deleted: false,
    },
    'f2623176-e2d6-59db-b6dd-e3a18c8e1d7f': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Which TV series should you watch? Choose whatever you like, as long as it’s not comedy. There is nothing quite as terrible as listening to a long German joke, reaching the end, and realizing that you don’t understand the punch line, because it’s a crappy pun on some rare word that only exists in some ridiculous German dialect. Don’t do this to yourself. Watch House or 24 or Some Guy Runs Around and Shoots People. In all likelihood, these shows have been professionally dubbed into your target language.',
        location: '2566-70',
        time: 'Tuesday, May 18, 2021, 06:39 PM',
        favourite: false,
        deleted: false,
    },
    'c46fd0eb-c5e7-5bd7-ad03-95f8d8af9470': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'You’ll have a much easier time understanding a TV show or movie if you read a summary of it first, particularly if that summary is in your target language.',
        location: '2579-80',
        time: 'Tuesday, May 18, 2021, 06:40 PM',
        favourite: false,
        deleted: false,
    },
    '3c4f3b7b-47c2-597e-aa55-fe3d4f554897': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Later, once you’re comfortable watching TV, you can begin to drop your crutches. You won’t need to read summaries on Wikipedia, and you’ll be able to handle films without much trouble. If you’re really adventurous, you might even be able to handle comedy (but don’t bother if you’re learning German). Then, if you want, you can make the step to audiobooks (without a book in hand), podcasts, and radio.',
        location: '2585-88',
        time: 'Tuesday, May 18, 2021, 06:41 PM',
        favourite: false,
        deleted: false,
    },
    '301b5d3d-822e-548c-bc1d-ec16085346f7': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: '• Start with an interesting foreign TV or dubbed American TV series without subtitles. You can dial down the difficulty by reading episode summaries ahead of time, in order to prepare yourself for the vocabulary and plot twists of each episode.',
        location: '2591-93',
        time: 'Tuesday, May 18, 2021, 06:42 PM',
        favourite: false,
        deleted: false,
    },
    'e4a83459-93a4-56ac-8919-27f3cac367a1': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Fluent speech and the game of Taboo are practically the same thing. When you speak in a foreign language, you try to communicate the thoughts in your head, but you don’t always have the right words to express them. You want to tell your German friend about a baseball game, but you don’t know the word for “baseball.” Perhaps you don’t even know the German words for “sport” or “game.” How do you communicate your thoughts to your friend?',
        location: '2606-9',
        time: 'Tuesday, May 18, 2021, 06:43 PM',
        favourite: false,
        deleted: false,
    },
    '49e6ac21-c33c-5cd2-bd89-715d452e8e5d': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'If you’re aiming for efficiency, then pull out a word frequency list and discuss every word you don’t know in order. This is what I do with my private English students, and it always provokes interesting, fun conversations. When we try to suss out the difference between a bar and a pub, for instance, we invariably get caught up in long discussions about German and American drinking cultures.',
        location: '2653-56',
        time: 'Tuesday, May 18, 2021, 06:48 PM',
        favourite: false,
        deleted: false,
    },
    'f37c60c3-7a13-54e8-ac6f-8fa9fe469dc8': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Take notes on everything you learn. This is your chance to pick up all the slang that’s missing from your textbook. If you want, you can even work with your tutor to generate example sentences for new flash cards. In the process, you’ll run into new grammar and new vocabulary, all while speaking in your target language.',
        location: '2658-60',
        time: 'Tuesday, May 18, 2021, 06:48 PM',
        favourite: false,
        deleted: false,
    },
    '09acfe88-6a1a-5c1d-9e55-7f16855255be': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Over the previous three chapters, I’ve suggested the following: 1. Sound Play: Learn how to hear and produce the sounds of your target language and how spelling and sound interrelate. 2. Word Play: Learn 625 frequent, concrete words by playing Spot the Differences in Google Images, finding personal connections, and if needed, adding mnemonic imagery for grammatical gender. 3. Sentence Play: Begin turning the sentences in your grammar book into flash cards for new words, word forms, and word order. Use written output to fill in the gaps missing from your textbook. Here’s what I suggest you do next: 1. If you haven’t already done so, learn the first half of your grammar book. Make flash cards for everything you find interesting. 2. Learn the top thousand words in your target language. Write out definitions and examples whenever you’re not entirely sure what a word means. About halfway through, you’ll find that you can understand a monolingual dictionary. Use it to help you learn the rest of your words. 3. Go back to your grammar book, skim through it, and grab any remaining bits of information you’d like. 4. Read your first book while listening to an audiobook. 5. Watch a full season of a dubbed TV show, reading episode summaries in your target language ahead of time. 6. Get a ton of speech practice. Get as much as you possibly can, either through an immersion program, a language holiday abroad, or through teachers on italki.com. If you get a private teacher, talk about the next thousand words from your frequency list and add specialized words for your particular interests. Together with your teacher, create example sentences and enter them into your SRS.',
        location: '2701-16',
        time: 'Tuesday, May 18, 2021, 06:53 PM',
        favourite: false,
        deleted: false,
    },
    'e202398c-dbea-5c70-ae1f-b4befcae3b90': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'The most efficient way to do this is by writing on Lang-8.com and speaking with tutors (on italki.com). Turn every mistake you make and every new word you want to learn into flash cards. Use a frequency list as conversation/essay fodder. Find the words you don’t know, discuss them with a tutor (or write about them), and make as many mistakes as you can (and tell your tutor to catch them). If you’re constantly speaking and writing, and you’re using your SRS to learn from all of your mistakes, then you’re going to improve at breakneck speed.',
        location: '2782-86',
        time: 'Tuesday, May 18, 2021, 06:58 PM',
        favourite: false,
        deleted: false,
    },
    '23808ba8-b971-5eac-8cf6-0fe115b5573d': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Even if you don’t change careers, you’ve potentially increased your salary by 5–20 percent. Employers are willing to pay more for bilingual employees, even when those employees never need their extra languages to do their work. Employers see language skills as a sign of intelligence and competence, and that puts you—their newly bilingual employee—in higher demand.',
        location: '2806-8',
        time: 'Tuesday, May 18, 2021, 06:59 PM',
        favourite: false,
        deleted: false,
    },
    '633c8b78-0b9b-51e4-8f8a-f0d46e3aa37c': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'We could keep going: • How do you pronounce “chèvre”? • How do you spell the word pronounced “sheh-vre” () • What’s a food that chèvres eat? • What colors do chèvres come in? • What’s your least favorite memory of a chèvre? But eventually, you’re going to get sick of chèvres, and your flash cards will be too easy.',
        location: '2920-25',
        time: 'Tuesday, May 18, 2021, 07:09 PM',
        favourite: false,
        deleted: false,
    },
    '92d357f4-cc2d-5353-b84f-1b28f5052593': {
        raw: 'fluentforever:howtolearnanylanguagefastandneverforgetit(9780385348102)',
        book: 'Fluent Forever : How to Learn Any Language Fast and Never Forget It (9780385348102) ',
        author: 'Gabriel Wyner',
        quote: 'Learn thirty flash cards a day. As you learn them, you’ll tell your SRS what you remember.',
        location: '3137',
        time: 'Tuesday, May 18, 2021, 11:10 PM',
        favourite: false,
        deleted: false,
    },
    '32f6f5e4-5871-5517-bed7-26b72dc6eaa7': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Similarly, when going on holiday we can choose between thousands of amazing destinations. But wherever we go, we are likely to be playing with our smartphone instead of really seeing the place. We have more choice than ever before, but no matter what we choose, we have lost the ability to really pay attention to it.6',
        location: '5377-79',
        time: 'Wednesday, May 19, 2021, 11:38 AM',
        favourite: false,
        deleted: false,
    },
    'bb0b29b9-2f55-5811-8359-e4f1be11e55b': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'In addition to smelling and paying attention, we have also been losing our ability to dream. Many cultures believed that what people see and do in their dreams is no less important than what they see and do while awake. Hence people actively developed their ability to dream, to remember dreams and even to control their actions in the dream world, which is known as ‘lucid dreaming’. Experts in lucid dreaming could move about the dream world at will, and claimed they could even travel to higher planes of existence or meet visitors from other worlds. The modern world, in contrast, dismisses dreams as subconscious messages at best, and mental garbage at worst.',
        location: '5380-84',
        time: 'Wednesday, May 19, 2021, 11:38 AM',
        favourite: true,
        deleted: false,
    },
    '600c3fd9-9799-5146-8c67-adc3508e3b48': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'But the friend has little time and energy for you, so he cuts you short, and tries to solve your problem: ‘Okay. I get it. Well, you really have just two options here: either quit the job, or stay and do what the boss wants. And if I were you, I would quit.’ That would hardly help. A really good friend will have patience, and will not be quick to find a solution. He will listen to your distress, and will provide time and space for all your contradictory emotions and gnawing anxieties to surface.',
        location: '5394-97',
        time: 'Wednesday, May 19, 2021, 11:40 AM',
        favourite: false,
        deleted: false,
    },
    '16552317-b8f5-5746-8508-251479a8852f': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'This extreme situation in which all data is processed and all decisions are made by a single central processor is called communism. In a communist economy, people allegedly work according to their abilities, and receive according to their needs. In other words, the government takes 100 per cent of your profits, decides what you need and then supplies these needs. Though no country ever realised this scheme in its extreme form, the Soviet Union and its satellites came as close as they could. They abandoned the principle of distributed data processing, and switched to a model of centralised data processing. All information from throughout the Soviet Union flowed to a single location in Moscow, where all the important decisions were made. Producers and consumers could not communicate directly, and had to obey government orders.',
        location: '5515-21',
        time: 'Wednesday, May 19, 2021, 11:54 AM',
        favourite: false,
        deleted: false,
    },
    '0ed88cff-ef82-5bf2-9bef-1ed676e0791d': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'These institutions evolved in an era when politics moved faster than technology. In the nineteenth and twentieth centuries, the Industrial Revolution unfolded slowly enough for politicians and voters to remain one step ahead of it and regulate and manipulate its course. Yet whereas the rhythm of politics has not changed much since the days of steam, technology has switched from first gear to fourth. Technological revolutions now outpace political processes, causing MPs and voters alike to lose control.',
        location: '5561-64',
        time: 'Wednesday, May 19, 2021, 04:26 PM',
        favourite: false,
        deleted: false,
    },
    '95ada774-e154-5c04-ad77-678692739831': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Some people believe that there is somebody in charge after all. Not democratic politicians or autocratic despots, but rather a small coterie of billionaires who secretly run the world. But such conspiracy theories never work, because they underestimate the complexity of the system. A few billionaires smoking cigars and drinking Scotch in some back room cannot possibly understand everything happening on the globe, let alone control it. ',
        location: '5607-10',
        time: 'Wednesday, May 19, 2021, 04:32 PM',
        favourite: false,
        deleted: false,
    },
    'a0db6258-521c-5bbb-b6ff-fcf2b6a5e3db': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'If the world’s richest man would like to make another billion dollars he could easily game the system in order to achieve his goal. In contrast, if he would like to reduce global inequality or stop global warming, even he won’t be able to do it, because the system is far too complex.',
        location: '5612-14',
        time: 'Wednesday, May 19, 2021, 04:32 PM',
        favourite: false,
        deleted: false,
    },
    '6e70dd89-8f60-5495-bc8c-1651a9c7840f': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'According to Dataism, human experiences are not sacred and Homo sapiens isn’t the apex of creation or a precursor of some future Homo deus. Humans are merely tools for creating the Internet-of-All-Things, which may eventually spread out from planet Earth to cover the whole galaxy and even the whole universe. This cosmic data-processing system would be like God. It will be everywhere and will control everything, and humans are destined to merge into it.',
        location: '5669-72',
        time: 'Wednesday, May 19, 2021, 04:38 PM',
        favourite: false,
        deleted: false,
    },
    'bb434395-cea1-5218-9211-2ab2a5c11382': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'On 11 January 2013, Dataism got its first martyr when Aaron Swartz, a twenty-six-year-old American hacker, committed suicide in his apartment. Swartz was a rare genius. At fourteen, he helped develop the crucial RSS protocol. Swartz was also a firm believer in the freedom of information. In 2008 he published the ‘Guerilla Open Access Manifesto’ that demanded a free and unlimited flow of information. Swartz said that ‘We need to take information, wherever it is stored, make our copies and share them with the world. We need to take stuff that’s out of copyright and add it to the archive. We need to buy secret databases and put them on the Web. We need to download scientific journals and upload them to file sharing networks. We need to fight for Guerilla Open Access.’',
        location: '5700-5705',
        time: 'Wednesday, May 19, 2021, 04:41 PM',
        favourite: false,
        deleted: false,
    },
    'f414385a-6d2a-546e-a9d0-b997748a0349': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'He believed that information wants to be free, that ideas don’t belong to the people who created them, and that it is wrong to lock data behind walls and charge money for entrance.',
        location: '5708-9',
        time: 'Wednesday, May 19, 2021, 04:42 PM',
        favourite: true,
        deleted: false,
    },
    '9de25e88-80dd-57c4-9909-9245c3d80cad': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'To convince sceptics, Dataist missionaries repeatedly explain the immense benefits of the freedom of information. Just as capitalists believe that all good things depend on economic growth, so Dataists believe all good things – including economic growth – depend on the freedom of information. Why did the USA grow faster than the USSR? Because information flowed more freely in the USA. Why are Americans healthier, wealthier and happier than Iranians or Nigerians? Thanks to the freedom of information. So if we want to create a better world, the key is to set the data free.',
        location: '5715-19',
        time: 'Wednesday, May 19, 2021, 04:42 PM',
        favourite: false,
        deleted: false,
    },
    '73f65c7c-95e2-5364-9255-8ffd928f354c': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Why do I need to keep it for the other twenty-three hours? We can create a smart car-pool system, run by computer algorithms. The computer would know that I need to leave home at 8:04, and would route the nearest autonomous car to pick me up at that precise moment. After dropping me off at campus, it would be available for other uses instead of waiting in the car park. At 18:11 sharp, as I leave the university gate, another communal car would stop right in front of me, and take me home. In such a way, 50 million communal autonomous cars may replace 1 billion private cars, and we would also need far fewer roads, bridges, tunnels and parking spaces. Provided, of course, I renounce my privacy and allow the algorithms to always know where I am and where I want to go.',
        location: '5727-32',
        time: 'Wednesday, May 19, 2021, 04:44 PM',
        favourite: false,
        deleted: false,
    },
    '59ccc325-0599-5308-a860-92643e9869c5': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'For true-believers, to be disconnected from the data flow risks losing the very meaning of life. What’s the point of doing or experiencing anything if nobody knows about it, and if it doesn’t contribute something to the global exchange of information?',
        location: '5751-52',
        time: 'Wednesday, May 19, 2021, 04:46 PM',
        favourite: false,
        deleted: false,
    },
    '9dc9c0bc-12bf-58df-8284-212259c581a2': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Humanism thought that experiences occur inside us, and that we ought to find within ourselves the meaning of all that happens, thereby infusing the universe with meaning. Dataists believe that experiences are valueless if they are not shared, and that we need not – indeed cannot – find meaning within ourselves. We need only record and connect our experience to the great data flow, and the algorithms will discover its meaning and tell us what to do.',
        location: '5753-56',
        time: 'Wednesday, May 19, 2021, 04:47 PM',
        favourite: false,
        deleted: false,
    },
    'd5af998e-3e84-5345-81d4-7f861a8c7712': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Writing a private diary – a common humanist practice in previous generations – sounds to many present-day youngsters utterly pointless. Why write anything if nobody else can read it? The new motto says: ‘If you experience something – record it. If you record something – upload it. If you upload something – share it.’',
        location: '5759-61',
        time: 'Wednesday, May 19, 2021, 04:47 PM',
        favourite: false,
        deleted: false,
    },
    '829d6034-0bb7-50e8-90a7-b447a0fe13d9': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'Dataist influence, both the life sciences and the social sciences have become obsessed with decision-making processes, as if that’s all there is to life. But is it so? Sensations, emotions and thoughts certainly play an important part in making decisions, but is that their sole meaning? Dataism gains a better and better understanding of decision-making processes, but it might be adopting an increasingly skewed view of life.',
        location: '5866-69',
        time: 'Wednesday, May 19, 2021, 05:00 PM',
        favourite: false,
        deleted: false,
    },
    '938827ad-9061-5a80-bf15-1ea1bf240a49': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: 'In the twenty-first century, censorship works by flooding people with irrelevant information. People just don’t know what to pay attention to, and they often spend their time investigating and debating side issues. In ancient times having power meant having access to data. Today having power means knowing what to ignore. So of everything that happens in our chaotic world, what should we focus on?',
        location: '5908-11',
        time: 'Wednesday, May 19, 2021, 05:05 PM',
        favourite: false,
        deleted: false,
    },
    'b30144ec-30a5-5b4d-b714-c1fd29913979': {
        raw: 'homodeus:abriefhistoryoftomorrow',
        book: 'Homo Deus: A Brief History of Tomorrow ',
        author: 'Yuval Noah Harari',
        quote: '1.  Are organisms really just algorithms, and is life really just data processing? 2.  What’s more valuable – intelligence or consciousness? 3.  What will happen to society, politics and daily life when non-conscious but highly intelligent algorithms know us better than we know ourselves?',
        location: '5919-21',
        time: 'Wednesday, May 19, 2021, 05:07 PM',
        favourite: false,
        deleted: false,
    },
    'ca914992-f7e0-59e3-bccf-b1e2766c20cb': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'You use photo reading to read one book every 30 minutes. It’s a revolutionary technique, developed by Paul Scheele, that uses the whole brain to read and allows you to extract the essential knowledge from a book in the shortest time possible. It can literally change your life. I’m not kidding!',
        location: '944-46',
        time: 'Wednesday, May 19, 2021, 10:24 PM',
        favourite: false,
        deleted: false,
    },
    'e89489b5-a670-5f47-abcf-a37b837154d8': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'Let me give you a suggestion: stop keeping all of those great ideas only inside your head because the next ones won’t have any room available! Write articles, write a book, and tell others. Do anything, just get them out of your head and into the world so that you can make room for the next great ideas to come!',
        location: '977-79',
        time: 'Wednesday, May 19, 2021, 10:28 PM',
        favourite: false,
        deleted: false,
    },
    'c99d5c00-93c8-55cd-a22d-52d95bbd42b9': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'After that, I ensure that my Morning Ritual is clear and that I’m able to start it as soon as I wake up; I make the decision of waking up early the night before so I can avoid negotiating with myself in the morning; and I make sure that I sleep a minimum of seven hours every night.',
        location: '1059-61',
        time: 'Wednesday, May 19, 2021, 10:35 PM',
        favourite: true,
        deleted: false,
    },
    '5a2650e9-6b18-5849-8e6f-6f3fd78a8afc': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'Step 3: Measure your progress to eliminate regret over a lack of perfection. You can use your growth and development as a source of motivation or you can keep being the prisoner of your frustrations, consuming the energy that could help you reach your destination.',
        location: '1077-79',
        time: 'Wednesday, May 19, 2021, 10:37 PM',
        favourite: false,
        deleted: false,
    },
    'c6491828-de81-51f5-b6d2-55bda96b3519': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'Small setbacks are almost unavoidable and herein lies the trap most people fall prey to as they plan to implement productive habits. They have a ‘perfect streak’ for a few days and afterwards they have a day when they don’t practise their habit at all. Because they lose their sense of perfection, they stop practising altogether and set out to restart this sequence of practising productive habits after a couple of days.',
        location: '1080-83',
        time: 'Wednesday, May 19, 2021, 10:37 PM',
        favourite: false,
        deleted: false,
    },
    '0f50c1e4-af2d-5c99-b181-64b96ae70d7a': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'Sleeping less than seven hours or more than eight hours will take its toll on your health.',
        location: '1113-14',
        time: 'Thursday, May 20, 2021, 02:09 PM',
        favourite: false,
        deleted: false,
    },
    '0759d33e-5a1d-523d-910a-1b5c7a2d7147': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'To conclude, you’ll feel much better if you wake up and get out of bed on time—even if you’ve not slept enough—rather than hitting snooze and then getting out of bed. Now that you know this, perhaps you’ll make a firm decision in the evening to get out of bed the following morning as soon as you hear the alarm clock go off.',
        location: '1569-71',
        time: 'Thursday, May 20, 2021, 02:38 PM',
        favourite: false,
        deleted: false,
    },
    '9f3ee2f6-eaa4-5970-97ab-81e43aee4cfa': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'There aren’t compelling studies that reveal the exact negative side effects that sleeping more than eight hours per night has on a person’s health. But this sleep behaviour has been correlated with depression and with difficulties regarding self-perception and the results achieved while performing day-to-day activities.',
        location: '1595-97',
        time: 'Thursday, May 20, 2021, 02:41 PM',
        favourite: false,
        deleted: true,
    },
    'ab8bebd6-48b0-5206-97d4-52cda4ef39b1': {
        raw: 'the5a.m.revolution:whyhighachieverswakeupearlyandhowyoucandoit,too',
        book: 'The 5 A.M. Revolution: Why High Achievers Wake Up Early and How You Can Do It, Too ',
        author: 'Dan Luca',
        quote: 'It really helps if you ‘tell’ your body that you’re planning to wake up at X hour so that it may prepare for this situation.',
        location: '1601-2',
        time: 'Thursday, May 20, 2021, 02:41 PM',
        favourite: false,
        deleted: true,
    },
};

export { clippings };
